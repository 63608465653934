import React from 'react'
import styled from 'styled-components'


const SiteMapBox = ({title, children, columnNum}) => {
  return (
    <Wrapper>
      { title && <h2>{title}</h2> }
      <Links columnNum={columnNum}>
        {children}
      </Links>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
  h2 {
    margin-bottom: 20px;
  }
`

const Links = styled.div`
  width: 100%;
  display: grid;
	grid-gap: 25px 40px;
	grid-template-columns: ${props => props.columnNum ? `repeat(${props.columnNum}, 1fr)` : `repeat(5, 1fr)`};	
	grid-template-rows: auto;
  @media screen and (max-width: 768px) {
    grid-gap: 15px 25px;
    grid-template-columns: repeat(3, 1fr);	
	}
`
export default SiteMapBox
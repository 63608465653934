import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Pagination from '@mui/material/Pagination'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import ContentContainer from '../components/UI/ContentContainer'
import SiteMapBox from '../components/ForSiteMap/SiteMapBox'
import { PL_LOAN_PAGES } from '../support'
import { getPaginationArray } from '../utils/blogRelated'

const SiteMap = () => {
  const data = useStaticQuery(graphql`
    query SiteMapBlogsQuery {
      allContentfulPageBlogPost(
        filter: {type: {ne: "Credit Score Club"}}
        sort: {fields: date, order: DESC}
      ) {
        nodes {
          heading
          slug
        }
      }
      allContentfulPageSeoPage {
        nodes {
          page
          slug
        }
      }
      allProgrammaticContentJson {
        nodes {
          slug
          h1
        }
      }
    }
  `)
  const blogs = data.allContentfulPageBlogPost.nodes
  const seoPages = data.allContentfulPageSeoPage.nodes
  const progPages = data.allProgrammaticContentJson.nodes
  const allPages = [...PL_LOAN_PAGES, ...seoPages, ...progPages]
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Site Map",
    "datePublished": "2022-06-07",
  }

  // for pagination
  const numPerPage = 45
  const numPages = Math.ceil(allPages.length / numPerPage)
  const paginationArray = getPaginationArray(allPages, numPages, numPerPage)

  const [page, setPage] = useState(0)

  const handleChangePage = useCallback((event, value) => {
    setPage(value - 1)
  })

  return (
    <Layout hasGetYourRate={false}>
      <Seo title="Site Map" structuredData={structuredData}/>
      <ContentContainer>
        <Wrapper>
          <h1>Site Map</h1>

          <SiteMapBox title='Society One'>
            <SiteMapLink to='/'>Home</SiteMapLink>
            <SiteMapLink to='/credit-score'>Credit Score</SiteMapLink>
            <SiteMapLink to='/personal-loans'>Personal Loans</SiteMapLink>
            <SiteMapLink to='/blog'>Blog</SiteMapLink>
            <SiteMapLink to='/privacypolicy'>Privacy Policy</SiteMapLink>
            <SiteMapLink to='/legal'>Legal</SiteMapLink>
            <SiteMapLink to='/about-us'>About Us</SiteMapLink>
            <SiteMapLink to='/contact'>Contact</SiteMapLink>
            <SiteMapLink to='/invest'>Invest</SiteMapLink>
            <SiteMapLink to='/guides'>Guides</SiteMapLink>
            {/* <SiteMapLink to='/societyone-app'>SocietyOne App</SiteMapLink> */}
            <SiteMapLink to='/wallet-terms-and-conditions'>Wallet Terms And Conditions</SiteMapLink>
            <SiteMapLink to='/hardship'>Hardship</SiteMapLink>
          </SiteMapBox>

          <SiteMapBox title='Personal Loan' columnNum='3'>
            {paginationArray[page] && paginationArray[page].map((allPage, index) => <SiteMapLink to={`/${allPage.slug}`} key={index}>{allPage.page || allPage.h1}</SiteMapLink>)}
          </SiteMapBox>            
          {numPages > 1 && 
            <PaginationWrapper>
              <Pagination count={numPages} size="small" variant="outlined" page={page+1} onChange={handleChangePage}/>
            </PaginationWrapper>
          }

          <SiteMapBox title='Market Place'>
            <SiteMapLink to='/marketplace/rates-and-fees'>Rates And Fees</SiteMapLink>
            <SiteMapLink to='/marketplace/help'>Help</SiteMapLink>
          </SiteMapBox>

          <SiteMapBox title='Guide' columnNum='3'>
            <SiteMapLink to='/guide/secured-vs-unsecured-loans'>Secured Vs Unsecured Loans</SiteMapLink>
            <SiteMapLink to='/guide/credit-score'>Credit Score</SiteMapLink>
            <SiteMapLink to='/guide/personal-loans'>Personal Loans</SiteMapLink>
            <SiteMapLink to='/guide/debt-consolidation'>Debt Consolidation</SiteMapLink>
            <SiteMapLink to='/guide/how-to-budget'>How To Budget</SiteMapLink>
            <SiteMapLink to='/guide/credit-report'>Credit Report</SiteMapLink>
            <SiteMapLink to='/guide/tax-time-eofy-guide'>Tax Time EOFY Guide</SiteMapLink>
            <SiteMapLink to='/guide/glossary'>Glossary</SiteMapLink>
          </SiteMapBox>

          <SiteMapBox title='About Us'>
            <SiteMapLink to='/about-us/careers'>Careers</SiteMapLink>
            <SiteMapLink to='/about-us/media'>Media</SiteMapLink>
          </SiteMapBox>

          <SiteMapBox title='Blog'  columnNum='3'>
            {blogs && blogs.map((blog, index) => <SiteMapLink to={`/blog/${blog.slug}`} key={index}>{blog.heading}</SiteMapLink>)}
          </SiteMapBox>
        </Wrapper>

      </ContentContainer>
    </Layout>      
  )
}

const Wrapper = styled.div`
	display: flex;
  flex-direction: column;
	align-items: center;
  justify-content: center;
	h1 {
		text-align: center; 
    margin-bottom: 50px;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		};
	};
  
`
const SiteMapLink = styled(Link)`
	cursor: pointer;
	font-size: 18px;
	text-decoration: none;
	&:hover {
		color: rgb(38 75 101);
    text-decoration: underline;
  };
  @media screen and (max-width: 768px) {
    font-size: 14px;
	}

`
const PaginationWrapper = styled.div`

  background-color: white;
  ${'' /* opacity:0.2; */}
  margin-bottom: 40px;
  li {
    margin: 0 3px;
  }
  .MuiButtonBase-root {
    color: var(--base-navy);
  }
`

export default SiteMap

import { useState } from "react"

export const blogTypeColor = ( type ) => {
  switch (type) {
    case "Personal Finance":
    case "Personal Loan":
      return "rgb(29 188 110)"
    case "Credit Score":
      return "rgb(71 78 211)"
    case "Education":
    case "General":
      return "rgb(38 75 101)"
    case "Debt Consolidation":
    case "Investor":
      return "rgb(38 75 101)"
    case "Car Loans":
      return "rgb(38 75 101)"
    case "Travel":
      return "rgb(38 75 101)"
    case "Wedding":
      return "rgb(38 75 101)"
    case "Home Renovation":
      return "rgb(38 75 101)"
  
    default:
      return "rgb(38 75 101)"
  }
} 

export const filterByBlogsType = (type, array) => {
  const arr = []
  array.forEach((item, index) => {
    if (type === 'All' || item.type === type) {
      arr.push(item)
    }
  })
  return arr
}

export const filterByBlogsTypeAndHeader = (type, header, array) => {
  const arr = []
  array.forEach((item, index) => {
    if (item.type === type && item.heading !== header) {
      arr.push(item)
    }
  })
  return arr
}

  // customized hooks to reset the state
export const useResetState = (initState) => {
  const [state, setState] = useState(initState)
  const resetState = () => {
    setState(initState)
    return initState
  }
  return [state, setState, resetState]
}

// separate blogs array as a arrays of array
export const getPaginationArray = (blogsArray, numPage, postsPerPage) => {
  const newPaginationArray = []
  for (let i = 1; i <= numPage; i++) {
    newPaginationArray.push(blogsArray.slice(postsPerPage * (i - 1), postsPerPage * i))
  }
  return newPaginationArray
}